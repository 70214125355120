import 'styles/globals.css';
import 'styles/antd.less';

import GoogleTagManager from 'utils/gtm';
import Head from 'next/head'
import IubendaCookie from 'utils/iubenda';
import Layout from '../components/layout';
import { SWRConfig } from 'swr';
import fetcher from 'utils/swr/fetcher';

function MyApp({ Component, pageProps: { layoutProps, ...pageProps } }) {
  return (
    <>
      <Head>
        {process.env.VERCEL_ENV !== "production" && (
          <meta key="robots" property="robots" content="noindex" />
        )}
        <meta name="viewport" content='width=device-width, initial-scale=1' />
      </Head>
      <GoogleTagManager>
        <IubendaCookie>
          <SWRConfig value={{ fetcher }}>
            <Layout {...layoutProps}>
              <Component {...pageProps} />
            </Layout>
          </SWRConfig>
        </IubendaCookie>
      </GoogleTagManager>
    </>
  )
}

export default MyApp
