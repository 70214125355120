import { DepartureIcon, PinIcon } from 'components/icons';

import MarkdownText from 'components/atoms/markdown'
import dynamic from 'next/dynamic'

const GoogleMapReact = dynamic(() => import('google-map-react'));

const AirportMarker = () => <DepartureIcon className="text-blue-900 w-7 h-7" />;
const ParkingMarker = () => <PinIcon className="text-blue-900 w-7 h-7" />;

export default function MapSection({
    airportCoords = {},
    parkingCoords = {},
    airportAddress = null,
    parkingAddress = null,
}) {
    const hasAirportCoord = !!airportCoords?.lat && Number(airportCoords?.lat) && !!airportCoords?.lng && Number(airportCoords?.lng);
    const hasParkingCoord = !!parkingCoords?.lat && Number(parkingCoords?.lat) && !!parkingCoords?.lng && Number(parkingCoords?.lng);
    const showMap = hasAirportCoord || hasParkingCoord;

    if (!showMap) return null;
    return (
        <section className="py-10">
            <div className="px-2 max-w-6xl mx-auto flex flex-col md:flex-row gap-8">
                {showMap && (
                    <div className="w-full aspect-w-16 aspect-h-9">
                        <div className='w-full h-full'>
                            <GoogleMapReact
                                bootstrapURLKeys={{ key: process.env.GOOGLE_MAPS_API_KEY }}
                                defaultCenter={{
                                    lat: Number(hasParkingCoord ? parkingCoords.lat : airportCoords.lat),
                                    lng: Number(hasParkingCoord ? parkingCoords.lng : airportCoords.lng),
                                }}
                                defaultZoom={11}
                            >
                                {hasAirportCoord && <AirportMarker {...airportCoords} />}
                                {hasParkingCoord && <ParkingMarker {...parkingCoords} />}
                            </GoogleMapReact>
                        </div>
                    </div>
                )}
                <div className="flex flex-row md:flex-col gap-8 justify-center min-w-[250px]">
                    {parkingAddress && <div>
                        <div className="flex flex-row items-center gap-2">
                            <ParkingMarker />
                            <span className="font-bold text-xl">Parking address</span>
                        </div>
                        <MarkdownText prose className="prose-p:m-0 text-lg">{parkingAddress}</MarkdownText>
                    </div>}
                    {airportAddress && <div>
                        <div className="flex flex-row items-center gap-2">
                            <AirportMarker />
                            <span className="font-bold text-xl">Airport address</span>
                        </div>
                        <MarkdownText prose className="prose-p:m-0 text-lg">{airportAddress}</MarkdownText>
                    </div>}
                </div>
            </div>
        </section>
    )
}
