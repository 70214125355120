import axios from "axios";
import axiosRetry, { exponentialDelay, isNetworkError, isRetryableError } from 'axios-retry';

const client = axios.create({
    timeout: 30_000,
});

const RETRY_METHODS = ['get', 'head', 'options', 'put', 'delete', 'post'];
axiosRetry(client, {
    retries: 3,
    retryDelay: exponentialDelay,
    retryCondition: (error) => isNetworkError(error) || isRetryableError(error),
});

export default client;
