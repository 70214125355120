import axios from "axios";
import qs from "qs";

const baseURL = process.env.STRAPI_BASE_URL;
const jwt = process.env.STRAPI_API_TOKEN;
const publicationState = process.env.STRAPI_PUBLICATION_STATE; // "live" or "preview"

const axiosInstance = axios.create({
    baseURL,
    headers: {
        Authorization: `Bearer ${jwt}`
    },
    paramsSerializer: (params) => {
        return qs.stringify(
            {
                ...params,
                publicationState
            },
            {
                encodeValuesOnly: true,
            }
        );
    }
});

const strapi = async (url, params = {}) => {
    try {
        const { data } = await axiosInstance.get(url, { params });
        return data;
    } catch (error) {
        console.error(error.toJSON());
        throw error;
    }
}

export default strapi;

export const getBySlug = async (apiPath, slug, options) => {
    const { data } = await strapi(apiPath, {
        pagination: { limit: 1, offset: 0 },
        ...options,
        filters: {
            ...(options.filters || {}),
            [options.slugFieldName || 'slug']: slug,
        },
    });
    return data[0];
}

export const getAllPages = async (apiPath, options = {}, pageSize = 100) => {
    const results = [];
    let start = 0;
    let lastResult = null;

    while (!lastResult || start < lastResult.meta.pagination.total) {
        const result = await strapi(apiPath, {
            pagination: { limit: pageSize, start },
            ...options,
        });
        lastResult = result;
        results.push(...result.data);
        start += pageSize;
    }
    return results;
}
