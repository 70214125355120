export const ClockIcon = ({ className }) => (
  <svg
    className={className || ""}
    viewBox="0 0 17 17"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="Website-V2"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Icons"
        transform="translate(-54.000000, -144.000000)"
        stroke="currentColor"
      >
        <g id="Group-17" transform="translate(55.000000, 145.000000)">
          <circle id="Oval" cx="7.5" cy="7.5" r="7.5"></circle>
          <polyline
            id="Path-7"
            strokeLinecap="round"
            points="7.5 4 7.5 8 9.5 10"
          ></polyline>
        </g>
      </g>
    </g>
  </svg>
);

export const PinIcon = ({ className }) => (
  <svg
    className={className || ""}
    viewBox="0 0 15 17"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="Website-V2"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Icons"
        transform="translate(-89.000000, -144.000000)"
        stroke="currentColor"
      >
        <path
          d="M101.313708,146.941125 C103.840253,149.46767 103.900409,153.526631 101.494176,156.126148 L101.313708,156.313708 L96.627417,161 L91.9411255,156.313708 L91.7794383,156.146353 C89.3541298,153.547705 89.4080256,149.474225 91.9411255,146.941125 C94.5292928,144.352958 98.7255412,144.352958 101.313708,146.941125 Z M96.627417,147.485281 C94.3397787,147.485281 92.4852814,149.339779 92.4852814,151.627417 C92.4852814,153.915055 94.3397787,155.769553 96.627417,155.769553 C98.9150553,155.769553 100.769553,153.915055 100.769553,151.627417 C100.769553,149.339779 98.9150553,147.485281 96.627417,147.485281 Z"
          id="Combined-Shape"
        ></path>
      </g>
    </g>
  </svg>
);

export const DepartureIcon = ({ className }) => (
  <svg
    className={className || ""}
    viewBox="0 0 21 17"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="Website-V2"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Icons"
        transform="translate(-159.000000, -144.000000)"
        stroke="currentColor"
      >
        <g id="Group-13" transform="translate(160.000000, 145.000000)">
          <path
            d="M3.66909085,1.99840144e-15 L11.507,4.174 L13.7292867,3.21340337 C14.3004345,2.96649264 14.9201437,2.85671725 15.5389935,2.89118966 L15.8040181,2.91481578 L17.9445899,3.17764495 C18.2078295,3.20996671 18.4474478,3.34553618 18.6107313,3.55452962 C18.9507518,3.98973594 18.8735884,4.61818141 18.4383821,4.95820185 L18.4383821,4.95820185 L16.8964855,6.16286347 C16.5680169,6.41949124 16.2014542,6.62320027 15.8100699,6.76661468 L15.8100699,6.76661468 L8.79457041,9.33729405 C7.91285916,9.66037823 6.98115094,9.8257056 6.04210987,9.8257056 L6.04210987,9.8257056 L2.4626459,9.8257056 L-1.28785871e-14,6.67366259 L1.18201613,5.75017038 L3.81701131,7.49854116 L7.004,6.12 L2.09306934,1.23132295 L3.66909085,1.99840144e-15 Z"
            id="Combined-Shape"
            strokeLinejoin="round"
          ></path>
          <line
            x1="8"
            y1="15.5"
            x2="3.64153152e-14"
            y2="15.5"
            id="Line-2"
            strokeLinecap="round"
          ></line>
        </g>
      </g>
    </g>
  </svg>
);

export const ArrivalIcon = ({ className }) => (
  <svg
    className={className || ""}
    viewBox="0 0 19 17"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="Website-V2"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Icons"
        transform="translate(-122.000000, -144.000000)"
        stroke="currentColor"
      >
        <g id="Group-12" transform="translate(123.000000, 145.000000)">
          <path
            d="M7,0 L10.606,8.114 L12.9491739,8.72587145 C13.5608148,8.88542995 14.1248166,9.18736442 14.5957603,9.60565224 L14.7679134,9.76791341 L16.2928932,11.2928932 C16.4804296,11.4804296 16.5857864,11.7347835 16.5857864,12 C16.5857864,12.5522847 16.1380712,13 15.5857864,13 L15.5857864,13 L13.6491106,13 C13.2191823,13 12.7920655,12.9306885 12.3841996,12.7947332 L12.3841996,12.7947332 L5.23160085,10.4105336 C4.4158691,10.138623 3.64930839,9.73698129 2.96142297,9.22106723 L2.96142297,9.22106723 L0,7 L0,3 L1.5,3 L2.5,6 L5.86,6.876 L5,0 L7,0 Z"
            id="Combined-Shape"
            strokeLinejoin="round"
          ></path>
          <line
            x1="17"
            y1="15.5"
            x2="9"
            y2="15.5"
            id="Line-2"
            strokeLinecap="round"
          ></line>
        </g>
      </g>
    </g>
  </svg>
);

export const SearchIcon = ({ className }) => (
  <svg
    className={className || ""}
    viewBox="0 0 18 19"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="Website-V2"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Icons"
        transform="translate(-198.000000, -144.000000)"
        stroke="currentColor"
      >
        <g id="Group-14" transform="translate(199.000000, 145.000000)">
          <circle id="Oval" cx="6.5" cy="6.5" r="6.5"></circle>
          <path
            d="M8.75,1.06801948 C6.26471863,1.06801948 4.25,3.08273811 4.25,5.56801948"
            id="Path"
            strokeLinecap="round"
            strokeLinejoin="round"
            transform="translate(6.500000, 3.318019) rotate(45.000000) translate(-6.500000, -3.318019) "
          ></path>
          <path
            d="M15,12 L15,15 C15,15.5522847 14.5522847,16 14,16 L13,16 C12.4477153,16 12,15.5522847 12,15 L12,12 L12,12"
            id="Path"
            strokeLinecap="round"
            strokeLinejoin="round"
            transform="translate(13.500000, 14.000000) rotate(-45.000000) translate(-13.500000, -14.000000) "
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

export const DoubleRightIcon = ({ className }) => (
  <svg
    className={className || ""}
    viewBox="0 0 22 22"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="Website-V2"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        id="Icons"
        transform="translate(-124.000000, -200.000000)"
        stroke="currentColor"
        strokeWidth="1.5"
      >
        <g id="Group-11" transform="translate(125.000000, 201.000000)">
          <polyline
            id="Path"
            transform="translate(5.000000, 10.000000) scale(1, -1) rotate(90.000000) translate(-5.000000, -10.000000) "
            points="-5 15 5 5 15 15"
          ></polyline>
          <polyline
            id="Path"
            transform="translate(15.000000, 10.000000) scale(1, -1) rotate(90.000000) translate(-15.000000, -10.000000) "
            points="5 15 15 5 25 15"
          ></polyline>
        </g>
      </g>
    </g>
  </svg>
);

export const ArrowDownIcon = ({ className }) => (
  <svg
    className={className || ""}
    viewBox="0 0 14 8"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="Website-V2"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        id="Icons"
        transform="translate(-308.000000, -148.000000)"
        stroke="currentColor"
        strokeWidth="1.5"
      >
        <polyline
          id="Path"
          transform="translate(315.000000, 152.000000) scale(1, -1) translate(-315.000000, -152.000000) "
          points="309 155 315 149 321 155"
        ></polyline>
      </g>
    </g>
  </svg>
);

export const ArrowUpIcon = ({ className }) => (
  <ArrowDownIcon className={`rotate-180 ${className}`} />
);

export const ArrowRightIcon = ({ className }) => (
  <ArrowDownIcon className={`-rotate-90 ${className}`} />
);

export const ArrowLeftIcon = ({ className }) => (
  <ArrowDownIcon className={`rotate-90 ${className}`} />
);

export const CrossIcon = ({ className, ...props }) => (
  <svg
    className={className || ""}
    {...props}
    width="13px"
    height="13px"
    viewBox="0 0 13 13"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="Website-V2"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        id="Icons"
        transform="translate(-332.000000, -146.000000)"
        stroke="currentColor"
        strokeWidth="1.5"
      >
        <g id="Group-18" transform="translate(333.000000, 147.000000)">
          <line
            x1="-1.0658141e-14"
            y1="-4.41313652e-15"
            x2="10.6066017"
            y2="10.6066017"
            id="Path"
          ></line>
          <line
            x1="-1.0658141e-14"
            y1="-4.41313652e-15"
            x2="10.6066017"
            y2="10.6066017"
            id="Path"
            transform="translate(5.303301, 5.303301) rotate(-90.000000) translate(-5.303301, -5.303301) "
          ></line>
        </g>
      </g>
    </g>
  </svg>
);

export const FiltersIcon = ({ className }) => (
  <svg
    className={className || ""}
    viewBox="0 0 20 14"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="Website-V2"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Icons"
        transform="translate(-270.000000, -145.000000)"
        stroke="currentColor"
        strokeWidth="1.5"
      >
        <g id="Group-10" transform="translate(271.000000, 146.000000)">
          <line
            x1="8"
            y1="2.5"
            x2="18"
            y2="2.5"
            id="Path-8"
            strokeLinecap="round"
          ></line>
          <line
            x1="0"
            y1="2.5"
            x2="4"
            y2="2.5"
            id="Path-8"
            strokeLinecap="round"
          ></line>
          <circle id="Oval" cx="6" cy="2" r="2"></circle>
          <line
            x1="7.49400542e-15"
            y1="10"
            x2="10"
            y2="10"
            id="Path-8"
            strokeLinecap="round"
          ></line>
          <line
            x1="14"
            y1="10"
            x2="18"
            y2="10"
            id="Path-8"
            strokeLinecap="round"
          ></line>
          <circle id="Oval" cx="12" cy="10" r="2"></circle>
        </g>
      </g>
    </g>
  </svg>
);

export const ExclamationIcon = ({ className }) => (
  <svg
    className={className || ""}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 128 512"
  >
    <path
      fill="currentColor"
      d="M64 352c17.69 0 32-14.32 32-31.1V64.01c0-17.67-14.31-32.01-32-32.01S32 46.34 32 64.01v255.1C32 337.7 46.31 352 64 352zM64 400c-22.09 0-40 17.91-40 40s17.91 39.1 40 39.1s40-17.9 40-39.1S86.09 400 64 400z"
    />
  </svg>
);

export const InfoIcon = ({ className }) => (
  <svg
    className={className || ""}
    xmlns="http://www.w3.org/2000/svg"
    height="1em"
    viewBox="0 0 512 512"
  >
    <path
      fill="currentColor"
      d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336h24V272H216 192V224h24 48 24v24 88h8 24v48H296 216 192V336h24zm72-144H224V128h64v64z"
    />
  </svg>
);
