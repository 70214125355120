import Script from 'next/script'

const iubendaImplementation = `
var _iub = _iub || [];
_iub.csConfiguration = {
  "consentOnContinuedBrowsing":false,
  "cookiePolicyInOtherWindow":true,
  "countryDetection":true,
  "floatingPreferencesButtonDisplay":"bottom-right",
  "gdprAppliesGlobally":false,
  "lang":"en-GB",
  "perPurposeConsent":true,
  "siteId":2743819,
  "whitelabel":false,
  "cookiePolicyId":32201238,
  "cookiePolicyUrl":"",
  "banner":{
    "acceptButtonCaptionColor":"#FFFFFF",
    "acceptButtonColor":"#0073CE",
    "acceptButtonDisplay":true,
    "backgroundColor":"#FFFFFF",
    "backgroundOverlay":true,
    "closeButtonDisplay":false,
    "customizeButtonCaptionColor":"#4D4D4D",
    "customizeButtonColor":"#DADADA",
    "customizeButtonDisplay":true,
    "fontSize":"16px",
    "position":"bottom",
    "prependOnBody":true,
    "rejectButtonCaptionColor":"#FFFFFF",
    "rejectButtonColor":"#0073CE",
    "rejectButtonDisplay":true,
    "textColor":"#000000",
    "rejectButtonCaption":"Decline"
  },
  "callback": {
    "onPreferenceExpressedOrNotNeeded": function(preference) {
        dataLayer.push({
            iubenda_ccpa_opted_out: _iub.cs.api.isCcpaOptedOut()
        });
        if (!preference) {
            dataLayer.push({
                event: "iubenda_preference_not_needed"
            });
        } else {
            if (preference.consent === true) {
                dataLayer.push({
                    event: "iubenda_consent_given"
                });
            } else if (preference.consent === false) {
                dataLayer.push({
                    event: "iubenda_consent_rejected"
                });
            } else if (preference.purposes) {
                for (var purposeId in preference.purposes) {
                    if (preference.purposes[purposeId]) {
                        dataLayer.push({
                            event: "iubenda_consent_given_purpose_" + purposeId
                        });
                    }
                }
            }
        }
    }
  }
};
`;

const IubendaCookie = ({ children }) => (
    <>
        <Script
            dangerouslySetInnerHTML={{ __html: iubendaImplementation }}
        />
        <Script
            src="https://cdn.iubenda.com/cs/iubenda_cs.js"
        />
        {children}
    </>
);

export default IubendaCookie;
