import React, { useEffect } from 'react';
import TagManager from 'react-gtm-module';

export const GTM_ID = "GTM-WD2L2RX";

const GoogleTagManager = ({ children }) => {
    useEffect(() => {
      TagManager.initialize({ gtmId: GTM_ID });
    }, []);

    return (
        <>
            {children}
        </>
    );
}

export default GoogleTagManager;
