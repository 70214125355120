import Link from 'next/link';
import React from "react";
import strapi from "utils/strapi";

const MenuLink = ({ item, onNavigate }) => (
    <Link
        href={item.path}
    >
        <a 
            target={item.external ? "_blank" : undefined}
            rel={item.external ? "noopener noreferer" : undefined}
            className="text-blue-500"
            onClick={onNavigate ? (() => onNavigate()) : undefined}
        >
            {item.title}
        </a>
    </Link>
)

const SubMenu = ({ items }) => {
    // TODO: handle nested paths!
    return (
        <ul>
            {items.map((subitem, idx) => (
                <li key={subitem.id || subitem.path || subitem.title || idx}>
                    {!!subitem.path ? <MenuLink item={subitem} /> : <>{subitem.title}</>}
                    {!!subitem.items && <SubMenu items={subitem.items} />}    
                </li>
            ))}
        </ul>
    )
}

const Navigation = ({ items, className, withDividers, onNavigate }) => {
    return (
        <nav className={`flex gap-6 ${className}`}>
            {(items || []).map((item, idx) => (
                <React.Fragment key={idx}>
                    <MenuLink item={item} onNavigate={onNavigate} />
                    {withDividers && idx !== items.length - 1 && (<hr key={item.id + 'hr'} className="border border-blue-100" />)}
                </React.Fragment>
            ))}
        </nav>
    )
}

export default Navigation;

export async function getNavigationProps() {
    const mainNavigation = await strapi("/api/navigation/render/main-navigation", { type: 'TREE', menu: 'true' });

    const items = [
        ...mainNavigation,
    ];

    return {
        items
    }
}
