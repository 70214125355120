import Header, { getHeaderProps } from "./header/header";

import SEO from "components/seo";
import dynamic from 'next/dynamic';
import { getFooterProps } from "utils/queries";
import strapi from "utils/strapi";

const Footer = dynamic(() => import('./footer/footer'));

const Layout = ({ children, ...layoutProps }) => {
    const { defaultSeo, headerProps, footerProps } = layoutProps || {};

    return (
        <div className="flex flex-col min-h-screen">
            <SEO {...defaultSeo} />
            <Header {...headerProps} />
            <div className="flex flex-col flex-1">
                {children}
            </div>
            <Footer {...footerProps} />
        </div>
    )
}

export default Layout;

export async function getLayoutProps(ctx, options = {}) {
    let defaultSeo;
    try {
        const { data: { attributes } } = await strapi("/api/global-defaults", { populate: [
            'defaultSeo',
            'defaultSeo.openGraphImage',
        ] });
        defaultSeo = attributes?.defaultSeo || {};
    } catch (error) {
        console.error("Error", error);
        defaultSeo = {};
    }

    return {
        defaultSeo,
        headerProps: await getHeaderProps(options),
        footerProps: await getFooterProps(options),
    }
}
